export default {
  state: {
    avatarUrl: null,
    loading: false,
  },
  getters: {
    getAvatarUrl: state => state.avatarUrl,
  },
  mutations: {
    setAvatarUrl: (state, payload) => {
      state.avatarUrl = payload;
    },
  },
  actions: {
    setAvatarUrl: ({ commit }, payload) => {
      commit("setAvatarUrl", payload);
    },
  },
}; 