import { createStore } from "vuex";

import account from "./account";
import defaultVariables from "./defaultVariables";
import navigation from "./navigation";
import search from "./search";
import signup from "./signup";
import tmp from "./tmp";
import user from "./user";

export const store = createStore({
  modules: {
    account,
    defaultVariables,
    navigation,
    search,
    signup,
    tmp,
    user,
  },
});
